/** @jsx jsx */
import { jsx } from "theme-ui";

import React from "react";
import Seo from "../components/Seo";
import { Flex, Box, Text } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";

function Home() {
  return (
    <>
      <Seo title="No encontrada" description="Pagina no encontrada" />
      <Flex mt={6} px={[3, null, 4]} sx={{ justifyContent: "center" }}>
        <Box>
          <Text
            as="h1"
            sx={{ fontSize: 4, fontFamily: "sans", lineHeight: 1 }}
            color="primary"
          >
            <span role="img" aria-label="thinking">
              🤔
            </span>{" "}
            Hmm... !no hay nada aquí!
          </Text>
          <Text
            as="p"
            sx={{ fontFamily: "sans", lineHeight: 1 }}
            py={3}
            color="primary"
          >
            ¿Qué tal ir a la{" "}
            <Text as={GatsbyLink} to="/">
              primera página
            </Text>
            ?
          </Text>
        </Box>
      </Flex>
    </>
  );
}

export default Home;
